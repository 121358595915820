import request from '@/utils/request'
const requestApi = {
  wms_picks_list: '/wms/warehouse/pick_list/',
  wms_picks_delete: '/wms/warehouse/pick_list/',
  wms_picks_declaration_status: '/wms/warehouse/order_declaration_status_list/',
  wms_pick_list_express: '/wms/warehouse/pick_list_express/',
  wms_pick_list_express_declaration: '/wms/warehouse/pick_list_express_declaration/',
  wms_pick_list_push_order: '/wms/warehouse/pick_list_push_order/',
  wms_pick_list_push_inventory: '/wms/warehouse/pick_list_push_inventory/',
  wms_picks_packer: '/wms/warehouse/pick_list_pack/',
  wms_picks_picker: '/wms/warehouse/pick_list_pick/',
  wms_picks_edit: '/wms/warehouse/pick_list/',
  picks_list: '/wms/warehouse/picks_id/',
  waybill_info: '/wms/waybill_info/',
  query_order_trace: '/wms/query_order_trace/',
  picklist_goods: 'wms/warehouse/pick_list/id/goods/',
  picklist_goods_by_order_no: 'wms/warehouse/pick_list/order_no/goods/',
  test_update_order_url: 'wms/warehouse/test_update_order/'
}
export function query_order_trace (data) {
  return request({
    url: requestApi.query_order_trace,
    method: 'post',
    data
  })
}
export function pick_list_express (data) {
  return request({
    url: requestApi.wms_pick_list_express,
    method: 'post',
    data
  })
}
export function pick_list_express_declaration (data) {
  return request({
    url: requestApi.wms_pick_list_express_declaration,
    method: 'post',
    data
  })
}
export function pick_list_push_order (data) {
  return request({
    url: requestApi.wms_pick_list_push_order,
    method: 'post',
    data
  })
}
export function pick_list_push_inventory (data) {
  return request({
    url: requestApi.wms_pick_list_push_inventory,
    method: 'post',
    data
  })
}
export function waybill_info (query) {
  return request({
    url: requestApi.waybill_info,
    method: 'get',
    params: query
  })
}
export function wms_picks_declaration_status (query) {
  return request({
    url: requestApi.wms_picks_declaration_status,
    method: 'get',
    params: query
  })
}
export function picks_list (query) {
  return request({
    url: requestApi.wms_picks_list,
    method: 'get',
    params: query
  })
}
export function picks_list_id (query) {
  return request({
    url: requestApi.picks_list,
    method: 'get',
    params: query
  })
}

export function wms_picks_packer (data) {
  return request({
    url: requestApi.wms_picks_packer,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_picks_edit (data, id) {
  return request({
    url: requestApi.wms_picks_edit + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function wms_picks_patch (data, id) {
  return request({
    url: requestApi.wms_picks_edit + id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_picks_picker (data) {
  return request({
    url: requestApi.wms_picks_picker,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function picks_delete (picks_id) {
  return request({
    url: requestApi.wms_picks_delete + picks_id + '/',
    method: 'delete'
  })
}

export function pick_list_commodities (id, query) {
  return request({
    url: requestApi.picklist_goods.replace('id', id),
    method: 'get',
    params: query
  })
}

export function pick_list_goods_by_order_no (order_no, query) {
  return request({
    url: requestApi.picklist_goods_by_order_no.replace('order_no', order_no),
    method: 'get',
    params: query
  })
}

export function test_update_order (data) {
  return request({
    url: requestApi.test_update_order_url,
    method: 'post',
    data
  })
}
